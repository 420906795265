<template>
  <transition name="bounce">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: "TransitionBounceComponent"
};
</script>

<style>
.bounce-enter,
.bounce-enter-to {
  animation: bounce-in 0.5s;
}
.bounce-leave,
.bounce-leave-to {
  display: none !important;
}

@keyframes bounce-in {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  5% {
    transform: translateY(-100%);
    opacity: 0;
  }
}
</style>
