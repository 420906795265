<template>
  <section class="description-documents table-items mt-5">
    <b-row class="table-items-header">
      <b-col xs="12" sm="12" md="2">
        <h6 class="h6 font-bold ml-2">Código</h6>
      </b-col>
      <b-col xs="12" sm="12" md="4">
        <h6 class="h6 font-bold">Detalle</h6>
      </b-col>
      <b-col xs="12" sm="12" md="2">
        <h6 class="h6 font-bold">Cantidad</h6>
      </b-col>
      <b-col xs="12" sm="12" md="2">
        <h6 class="h6 font-bold">Precio U.</h6>
      </b-col>
      <b-col xs="12" sm="12" md="2">
        <h6 class="h6 font-bold">Subtotal</h6>
      </b-col>
    </b-row>
    <el-form-item prop="products">
      <b-row
        class="mt-2 d-flex align-items-center"
        v-for="(product, indexProduct) in products"
        :key="indexProduct"
      >
        <b-col xs="12" sm="12" md="2">
          <p class="ml-2">{{ getPropertyProduct(product, "code") }}</p>
        </b-col>
        <b-col xs="12" sm="12" md="4">
          <p class="m-0">{{ getPropertyProduct(product, "name") }}</p>
          <InputTextComponent
            maxlength="null"
            :showWordLimit="false"
            :value="product.product_detail"
            @onInputChange="v => onInputChange(v, indexProduct)"
            rows="none"
            :isDisabled="isDisabled"
            resize="vertical"
          />
        </b-col>
        <b-col xs="12" sm="12" md="2">
          <el-input-number
            size="small"
            v-model="product.product_quantity"
            :min="1"
            :max="getQuantityProductByEmissionPoint(products[indexProduct])"
            :precision="2"
            :step="0.1"
            class="input-number-quantity"
            @change="onCheckValueProductQuantity(indexProduct)"
          ></el-input-number>
        </b-col>
        <b-col xs="12" sm="12" md="2">
          <el-input-number
            :controls="false"
            placeholder="Precio"
            v-model="product.product_amount"
            :precision="2"
            :step="0.1"
            @change="onCheckValueProductAmount(indexProduct)"
          ></el-input-number>
        </b-col>
        <b-col xs="12" sm="12" md="2">
          <p class="m-0 d-inline">
            ${{ handleCalculateSubTotal(indexProduct) }}
          </p>

          <ButtonIcoComponent
            class="float-right"
            @onClick="removeProducts(indexProduct)"
          />
        </b-col>
      </b-row>
    </el-form-item>

    <SelectProductsComponent
      v-if="isSearchProducts"
      autocomplete
      @onSelectProduct="onSelectProduct"
      :emision-point-id="emissionPointId"
      :validateStock="validateStock"
      :validateEmissionPoint="validateEmissionPoint"
    />
    <!-- <InputProductSearchComponent @onChangeSearch="onChangeSearch" />-->
    <hr style="margin-top: 12rem;" />
  </section>
</template>

<script>
import SelectProductsComponent from "../../components/SelectProducts";
import ButtonIcoComponent from "../../components/ButtonIco";
import InputTextComponent from "@/modules/dashboard/components/InputText";
//import InputProductSearchComponent from "../../components/InputProductSearch";
import { parseFloatFormat } from "@/assets/utils/format";

export default {
  name: "TableInputProducts",
  data() {
    return {
      products: this.setProducts,
      emissionPointId: this.setEmissionPointId
    };
  },
  watch: {
    setProducts(products) {
      this.products = products;
    },
    setEmissionPointId(id) {
      this.emissionPointId = id;
    }
  },
  methods: {
    onChangeSearch({ search }) {
      this.searchQuery = search;
    },
    getPropertyProduct(product, property) {
      return product[property] || product.product[property];
    },
    handleCalculateSubTotal(indexProduct) {
      const { product_amount = 0, product_quantity = 0 } = this.products[
        indexProduct
      ];

      const sub_total = product_quantity * product_amount;

      return parseFloatFormat(sub_total);
    },
    removeProducts(index) {
      this.products.splice(index, 1);
      this.onToogleProducts();
    },
    onSelectProduct({ product }) {
      const {
        id,
        id: user_business_product_id, // Se duplica porque en quotes se toma id y en las otros document user_business_product_id
        code,
        name,
        amount: product_amount,
        description: product_detail,
        stock: product_quantity,
        taxes_code = 2,
        taxes_code_percentage = 2,
        taxes_percentage = 12,
        stocks,
        is_stock_counter
      } = product;

      this.products.push({
        id,
        user_business_product_id,
        code,
        name,
        product_amount,
        product_detail,
        product_quantity,
        taxes_code,
        taxes_code_percentage,
        taxes_percentage,
        stocks,
        is_stock_counter
      });
      this.onToogleProducts();
    },
    onToogleProducts() {
      this.$emit("onToogleProducts", { products: this.products });
    },
    onInputChange(v, indexProduct) {
      this.products[indexProduct].product_detail = v;
      this.onToogleProducts();
    },
    onCheckValueProductQuantity(indexProduct) {
      const product = this.products[indexProduct];

      if (!this.validateStock) {
        product.product_quantity = product.product_quantity || 1;
      } else if (product.is_stock_counter) {
        const quantity = this.getQuantityProductByEmissionPoint(product);
        product.product_quantity = Math.min(product.product_quantity, quantity);
        if (product.product_quantity === quantity) {
          this.$notifications.warning({
            message: `El límite de stock es: ${quantity}`
          });
        }
      } else {
        product.product_quantity = product.product_quantity || 1;
      }

      this.onToogleProducts();
    },
    onCheckValueProductAmount(indexProduct) {
      this.products[indexProduct].product_amount =
        this.products[indexProduct].product_amount || 0;

      this.onToogleProducts();
    },
    getQuantityProductByEmissionPoint(product) {
      if (this.validateEmissionPoint && this.validateStock) {
        if (product.is_stock_counter === 1) {
          const found = product.stocks.find(item =>
            item.establishment_emision_points.some(
              emisionPoint => emisionPoint.id === this.emissionPointId
            )
          );
          return found.quantity;
        }
      }
    }
  },
  components: {
    SelectProductsComponent,
    ButtonIcoComponent,
    InputTextComponent
    //InputProductSearchComponent
  },
  props: {
    setProducts: {
      default: []
    },
    setEmissionPointId: {
      type: Number,
      default: 1,
      required: false
    },
    isSearchProducts: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    validateStock: {
      type: Boolean,
      default: false
    },
    validateEmissionPoint: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
