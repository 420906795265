<template>
  <div class="">
    <el-form :model="form" :rules="rules" ref="form">
      <TopBarTitleComponent title="Nueva Cotización" :showBackIcon="true">
        <template slot="right-area">
          <div class="d-flex justify-content-end align-items-center">
            <ButtonPrimaryComponent
              title="Guardar"
              @onSaveClick="handleSaveClick"
            />
          </div>
        </template>
      </TopBarTitleComponent>
      <b-container fluid class="pb-4 px-lg-4">
        <b-row>
          <b-col>
            <div class="card box-shadow">
              <div class="card-body">
                <b-row>
                  <b-col xs="12" sm="12" md="12">
                    <el-form-item prop="name" class="mb-4">
                      <el-input
                        placeholder="Nombre de tu cotización"
                        v-model="form.name"
                        autocomplete="none"
                        id="input-text-top-bar"
                        class="h2 font-semi-bold"
                      ></el-input>
                    </el-form-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col xs="12" sm="12" md="3">
                    <el-form-item prop="user_business_client_id">
                      <SelectBusinessComponent
                        @onChangeSelect="onChangeSelectBusiness"
                      />
                    </el-form-item>
                  </b-col>
                  <b-col xs="12" sm="12" md="3">
                    <el-form-item prop="user_business_client_contact_id">
                      <slot name="label">
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <label class="font-semi-bold m-0">Nombre</label>
                          <LinkPlus
                            onlyIcon
                            @onClick="onAddBusinnesClientContact"
                            v-if="form.user_business_client_id"
                          />
                        </div>
                      </slot>
                      <el-select
                        filterable
                        v-model="form.user_business_client_contact_id"
                        placeholder="Seleccionar"
                      >
                        <el-option
                          v-for="contact in client_contacts"
                          :key="contact.id"
                          :label="contact.name"
                          :value="contact.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </b-col>
                  <b-col xs="12" sm="12" md="3">
                    <InputDatePickerComponent
                      @onChangeDatePicker="
                        date => onChangeDatePicker(date, 'date_at')
                      "
                      prop="date_at"
                      placeholder=""
                      title="Fecha de emisión"
                      :setDate="form.date_at"
                    />
                  </b-col>
                  <b-col xs="12" sm="12" md="3">
                    <InputDatePickerComponent
                      @onChangeDatePicker="
                        date => onChangeDatePicker(date, 'validate_at')
                      "
                      prop="validate_at"
                      placeholder=""
                      title="Validez Cotización"
                      :setDate="form.validate_at"
                    />
                  </b-col>
                  <!-- <b-col xs="12" sm="12" md="6">
                    <b-row>
                      <b-col xs="12" sm="12" md="6">
                        <el-form-item prop="user_business_client_id">
                          <SelectBusinessComponent
                            @onChangeSelect="onChangeSelectBusiness"
                          />
                        </el-form-item>
                      </b-col>
                      <b-col xs="12" sm="12" md="6">
                        <el-form-item prop="user_business_client_contact_id">
                          <slot name="label">
                            <label class="font-semi-bold m-0">Nombre</label>
                          </slot>
                          <el-select
                            filterable
                            v-model="form.user_business_client_contact_id"
                            placeholder="Seleccionar"
                          >
                            <el-option
                              v-for="contact in client_contacts"
                              :key="contact.id"
                              :label="contact.name"
                              :value="contact.id"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col xs="12" sm="12" md="6">
                        <InputDatePickerComponent
                          @onChangeDatePicker="
                            date => onChangeDatePicker(date, 'date_at')
                          "
                          prop="date_at"
                          placeholder=""
                          title="Fecha de emisión"
                          :setDate="form.date_at"
                        />
                      </b-col>
                      <b-col xs="12" sm="12" md="6">
                        <InputDatePickerComponent
                          @onChangeDatePicker="
                            date => onChangeDatePicker(date, 'validate_at')
                          "
                          prop="validate_at"
                          placeholder=""
                          title="Validez Cotización"
                          :setDate="form.validate_at"
                        />
                      </b-col>
                    </b-row>
                  </b-col> -->
                  <!-- <b-col xs="12" sm="12" md="6">
                    <el-form-item prop="city_id">
                      <slot name="label">
                        <label class="font-semi-bold m-0">Ciudad</label>
                      </slot>
                      <el-select
                        filterable
                        v-model="form.city_id"
                        placeholder="Seleccionar"
                      >
                        <el-option
                          v-for="city in cities"
                          :key="city.id"
                          :label="city.name"
                          :value="city.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </b-col> -->
                </b-row>
                <TableInputProducts
                  @onToogleProducts="onToogleProducts"
                  :setProducts="form.products"
                />
                <section class="aditional-quotes mt-4">
                  <b-row>
                    <b-col xs="12" sm="12" md="6">
                      <el-form-item prop="additional_specifications">
                        <slot name="label">
                          <label class="font-semi-bold m-0">
                            Especificaciones adicionales
                          </label>
                        </slot>

                        <ButtonPlusComponent
                          @onButtonPlusClick="
                            handleSaveAdditionalSpecifications
                          "
                        />

                        <b-row
                          class="my-4"
                          v-for="(specification,
                          index) in form.additional_specifications"
                          :key="index"
                        >
                          <b-col xs="10" sm="11" md="11">
                            <el-form-item
                              :prop="'additional_specifications.' + index"
                              :rules.sync="
                                getSyncRules && getSyncRules.rules.required()
                              "
                            >
                              <el-input
                                v-model="form.additional_specifications[index]"
                                type="text"
                                placeholder="Escriba el detalle"
                              ></el-input>
                            </el-form-item>
                          </b-col>
                          <b-col xs="2" sm="1" md="1">
                            <ButtonIcoComponent
                              class="float-right"
                              @onClick="removeAdditionalSpecifications(index)"
                            />
                          </b-col>
                        </b-row>
                      </el-form-item>

                      <el-form-item prop="method_payment">
                        <slot name="label">
                          <label class="font-semi-bold m-0 w-100">
                            Formas de pago
                          </label>
                        </slot>
                        <el-radio-group v-model="form.method_payment">
                          <el-radio :label="1">De contado</el-radio>
                          <el-radio :label="2">Crédito</el-radio>
                          <el-radio :label="3">
                            Anticipo / Contra entrega
                          </el-radio>
                        </el-radio-group>
                      </el-form-item>

                      <section
                        class="method-payment-options"
                        v-if="form.method_payment === 2"
                      >
                        <p class="font-semi-bold mt-4 w-100">
                          Factura a Crédito
                        </p>

                        <b-row>
                          <b-col xs="12" sm="6" md="6">
                            <el-form-item prop="limit_credit">
                              <slot name="label">
                                <label class="font-semi-bold m-0">Plazo</label>
                              </slot>
                              <el-select
                                v-model="form.limit_credit"
                                placeholder="Seleccionar"
                              >
                                <el-option
                                  v-for="(limit, index) in limitCredits"
                                  :key="index"
                                  :label="limit.label"
                                  :value="limit.value"
                                ></el-option>
                              </el-select>
                            </el-form-item>
                          </b-col>
                          <b-col xs="12" sm="6" md="6">
                            <InputDatePickerComponent
                              @onChangeDatePicker="
                                date =>
                                  onChangeDatePicker(date, 'limit_credit_at')
                              "
                              prop="limit_credit_at"
                              placeholder=""
                              title="Fecha"
                              :setDate="form.limit_credit_at"
                              v-if="form.limit_credit === 'others'"
                            />
                          </b-col>
                        </b-row>
                      </section>
                      <section
                        class="method-payment-options"
                        v-if="form.method_payment === 3"
                      >
                        <p class="font-semi-bold mt-4 w-100">
                          Factura a Anticipo / Contra entrega
                        </p>

                        <b-row>
                          <b-col xs="12" sm="12" md="12">
                            <el-input
                              v-model="form.observation_advance"
                              type="textarea"
                            ></el-input>
                          </b-col>
                        </b-row>
                      </section>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" offset-md="2">
                      <DocumentCalculator
                        :setProducts="form.products"
                        @onChangeSwitchDiscount="onChangeSwitchDiscount"
                      />
                    </b-col>
                  </b-row>
                </section>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </el-form>
    <!-- dialog to add new contact -->
    <el-dialog
      :visible.sync="dialogAddContact"
      :fullscreen="false"
      :before-close="handleCloseContactAdd"
    >
      <AddContactComponent
        :business_client_id="form.user_business_client_id"
        :showBackIcon="false"
        v-if="dialogAddContact"
        @onSuccess="onSuccessContactAdd"
      />
    </el-dialog>
  </div>
</template>

<script>
import ButtonPrimaryComponent from "../../../../components/ButtonPrimary";
import TopBarTitleComponent from "../../../../components/TopBarTitle";
import ButtonPlusComponent from "../../../../components/ButtonPlus";
import ButtonIcoComponent from "../../../../components/ButtonIco";
import SelectBusinessComponent from "../../../../components/SelectBusinessV2";
import InputDatePickerComponent from "../../../../components/InputDatePicker";
import TableInputProducts from "../../../components/TableInputProducts";
import DocumentCalculator from "../../../components/DocumentCalculator";
import LinkPlus from "../../../../components/LinkPlus";
import AddContactComponent from "../components/AddContact";

import moment from "moment";
import Validate from "@/assets/validate";
import CitiesServices from "@/services/CitiesServices";
import quoteService from "../services/quoteService";
import { limitCredits } from "../assets/resources";

const DATE_AT = moment().format("YYYY-MM-DD");
const DATE_TO_AT = moment()
  .add(15, "days")
  .format("YYYY-MM-DD");

export default {
  name: "QuoteAddPage",
  data() {
    return {
      cities: [],
      client_contacts: [],
      limitCredits,
      form: {
        name: "",
        user_business_client_id: "",
        user_business_client_contact_id: "",
        date_at: DATE_AT,
        validate_at: DATE_TO_AT,
        city_id: "",
        discount: 0,
        discount_type: "",
        products: [],
        additional_specifications: [],
        method_payment: 1,
        limit_credit: "",
        limit_credit_at: "",
        observation_advance: ""
      },
      rules: {
        name: [Validate.rules.required()],
        user_business_client_id: [Validate.rules.required("change")],
        //user_business_client_contact_id: [Validate.rules.required("change")],
        date_at: [Validate.rules.required()],
        validate_at: [Validate.rules.required()],
        // city_id: [Validate.rules.required("change")],
        method_payment: [Validate.rules.required("change")],
        limit_credit: [Validate.rules.required("change")],
        limit_credit_at: [Validate.rules.required("change")],
        observation_advance: [Validate.rules.required()]
      },
      dialogAddContact: false
    };
  },
  computed: {
    getSyncRules() {
      return Validate;
    }
  },
  methods: {
    handleSaveAdditionalSpecifications() {
      this.form.additional_specifications.push("");
    },
    removeAdditionalSpecifications(index) {
      this.form.additional_specifications.splice(index, 1);
    },
    onChangeDatePicker({ date }, property) {
      this.form[property] = date;
    },
    onToogleProducts({ products }) {
      this.form.products = [...products];
    },
    onChangeSelectBusiness({ client }) {
      const { id, contacts } = client;
      this.form.user_business_client_id = id;
      this.client_contacts = contacts;
      this.handleResetFormInput("user_business_client_contact_id");
    },
    onChangeSwitchDiscount({ discount, type }) {
      this.form.discount = discount;
      this.form.discount_type = type;
    },
    onValidateFormProducts() {
      return this.form.products.length >= 1 ? true : false;
    },
    handleSaveClick() {
      if (!this.onValidateFormProducts()) {
        const message = "Debe agregar productos a la cotización.";
        this.$notifications.error({ message });
        return;
      }

      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          try {
            let response = await quoteService
              .addQuotes(this.form)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });

            const { success, data } = response.data;
            if (success) {
              const { user_business_quote_id, user_business_quote_v_id } = data;
              this.$refs.form.resetFields();

              this.goRouter(
                "sales.quotes.view",
                {
                  id: user_business_quote_id,
                  idVersion: user_business_quote_v_id
                },
                {
                  quoteSendEmail: "success"
                }
              );
            }
          } catch (error) {
            return false;
          }
        }
      });
    },
    handleResetFormInput(input) {
      this.form[input] = "";
    },
    goRouter(name, params = {}, query = {}) {
      this.$router.push({ name, params, query });
    },
    async getCities() {
      await CitiesServices.getCities().then(response => {
        if (response.data.success) this.cities = response.data.cities;
      });
    },
    /* dialog to add conctact */
    onSuccessContactAdd({ contact }) {
      this.client_contacts = [contact, ...this.client_contacts];
      this.form.user_business_client_contact_id = contact.id;
      this.dialogAddContact = false;
    },
    onAddBusinnesClientContact() {
      this.dialogAddContact = true;
    },
    handleCloseContactAdd() {
      this.dialogAddContact = false;
    }
  },
  components: {
    ButtonPrimaryComponent,
    TopBarTitleComponent,
    ButtonPlusComponent,
    ButtonIcoComponent,
    SelectBusinessComponent,
    InputDatePickerComponent,
    TableInputProducts,
    DocumentCalculator,
    AddContactComponent,
    LinkPlus
  },
  mounted() {
    this.getCities();
  }
};
</script>

<style lang="sass">
.date-picker-quote
  .el-input__prefix
    right: 0px !important;
    left: auto;
.number-descount
  .el-input__inner
    min-height: 40px;
    width: 60px;

.el-autocomplete
  width: 100% !important;
.my-autocomplete
  li
    line-height: normal;
    padding: 7px;
    .value
      text-overflow: ellipsis;
      overflow: hidden;
    .link
      font-size: 12px;
      color: #b4b4b4;

.el-icon-delete
  font-size: 1.5rem;
</style>
