<template>
  <button class="btn md__btn-link bgt p-0 m-0" @click.prevent="handleClick">
    <img :src="icoSrc" alt="" width="24" />
  </button>
</template>

<script>
import { icoTrash, icoArrowLeft } from "@/services/resources";

export default {
  name: "ButtonIcoComponent",
  data() {
    return {
      icoTrash,
      icoArrowLeft,
      icoSrc: null
    };
  },
  methods: {
    handleClick() {
      this.$emit("onClick");
    }
  },
  props: {
    ico: {
      default: "icoTrash"
    }
  },
  mounted() {
    this.icoSrc = this.$data[`${this.ico}`];
  }
};
</script>

<style lang="scss" scoped></style>
