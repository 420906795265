export const documentTypes = [
  {
    id: 1,
    name: "RUC"
  },
  {
    id: 2,
    name: "Cedula"
  },
  {
    id: 3,
    name: "Pasaporte"
  },
  {
    id: 4,
    name: "Consumidor Final"
  },
  {
    id: 5,
    name: "Identificación del Exterior"
  }
];

export const businessTypes = [
  {
    id: 1,
    name: "Cliente"
  },
  {
    id: 2,
    name: "Proveedor"
  }
];

export const bankAccountTypes = [
  {
    id: 1,
    name: "Ahorro"
  },
  {
    id: 2,
    name: "Corriente"
  }
];

export const businessActivitySales = [
  {
    id: 1,
    name: "Productos"
  },
  {
    id: 2,
    name: "Servicios"
  }
];
