import Api, { configFormData } from "@/config/Api";

export default {
  getQuotes(options = {}) {
    return Api().get(`/quotes`, options);
  },

  getClientQuotes(clientId, options = {}) {
    return Api().get(`/quotes?client=${clientId}`, options);
  },

  getQuoteId(quoteId) {
    return Api().get(`/quotes/${quoteId}`);
  },

  addQuotes(data) {
    return Api().post("/quotes", data);
  },

  updateQuoteId(quoteId, data) {
    return Api().put(`/quotes/${quoteId}`, data);
  },

  deleteQuoteId(quoteId) {
    return Api().delete(`/quotes/${quoteId}`, {});
  },

  updateQuoteStatus(quoteId, data) {
    return Api().put(`/status/quotes/${quoteId}`, data);
  },

  sendQuoteEmail({ data }) {
    return Api().post(`/send/quotes`, data, configFormData);
  },

  sendQuoteToInvoice(data) {
    return Api().post(`/quotes/to/invoice`, data);
  },

  downloadQuote(data) {
    return Api().get(
      `/download/quotes?quote_id=${data.quoteId}&quote_version_id=${data.quoteVersionId}`,
      { responseType: "arraybuffer" }
    );
  }
};
