<template>
  <el-form-item :prop="prop">
    <slot name="label">
      <label class="font-semi-bold m-0 d-block">{{ title }}</label>
    </slot>
    <el-date-picker
      :clearable="false"
      v-model="date"
      @change="onChangeDatePicker"
      type="date"
      :placeholder="placeholder"
      align="right"
      class="w-100 date-picker"
      format="dd-MM-yyyy"
      value-format="yyyy-MM-dd"
      :picker-options="isDisabledDate && pickerOptions"
    >
    </el-date-picker>
  </el-form-item>
</template>

<script>
export default {
  name: "InputDatePickerComponent",
  data() {
    return {
      date: this.setDate,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  watch: {
    setDate(v) {
      this.date = v;
    }
  },
  methods: {
    onChangeDatePicker() {
      this.$emit("onChangeDatePicker", { date: this.date });
    }
  },
  props: {
    prop: {
      default: null
    },
    title: {
      default: null
    },
    placeholder: {
      default: null
    },
    setDate: {
      default: null
    },
    isDisabledDate: {
      default: false
    }
  }
};
</script>

<style lang="sass">
.date-picker
  .el-input__prefix
    right: 0px !important;
    left: auto;
.el-input--prefix .el-input__inner
  padding-left: 15px !important;
</style>
