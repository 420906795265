<template>
  <button
    class="btn-lg md__btn-primary float-right"
    id="business-add-contact"
    @click.prevent="handleClick"
  >
    <i class="el-icon-plus"></i>
  </button>
</template>

<script>
export default {
  name: "ButtonPlusComponent",
  methods: {
    handleClick() {
      this.$emit("onButtonPlusClick");
    }
  }
};
</script>

<style lang="scss" scoped>
#business-add-contact {
  width: 30px !important;
  height: 30px !important;
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  outline: none;
  i {
    font-size: 12px !important;
  }
}
</style>
