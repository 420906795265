<template>
  <div class="">
    <el-form :model="form" :rules="rules" ref="form">
      <TopBarTitleComponent title="Directorio" :showBackIcon="showBackIcon">
        <template slot="right-area">
          <div class="d-flex justify-content-end align-items-center">
            <ButtonPrimaryComponent
              title="Guardar"
              @onSaveClick="handleSaveClick"
            />
          </div>
        </template>
      </TopBarTitleComponent>

      <b-container fluid class="pb-4 px-lg-4">
        <b-row>
          <b-col xs="12" sm="12" md="6">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <h4 class="h4 font-bold text-color-primary">
                Datos de empresa
              </h4>
            </div>
            <div class="card card-shadow my-3">
              <div class="card-body card-shadow-body">
                <FileUploadComponent
                  title="Subir Logo"
                  :showFileList="false"
                  @fileBase64="onFileBase64"
                />
                <h4 class="h4 font-bold line-bottom my-4 text-color-primary">
                  Información básica
                </h4>
                <b-row>
                  <b-col xs="12" sm="12" md="6">
                    <el-form-item prop="contact_type">
                      <slot name="label">
                        <label class="font-semi-bold m-0"
                          >Tipo de empresa</label
                        >
                      </slot>
                      <el-select
                        v-model="form.contact_type"
                        placeholder="Seleccionar"
                      >
                        <el-option
                          v-for="(type, index) in businessTypes"
                          :key="index"
                          :label="type.name"
                          :value="type.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </b-col>
                  <b-col xs="12" sm="12" md="6">
                    <el-form-item prop="tradename">
                      <slot name="label">
                        <label class="font-semi-bold m-0"
                          >Nombre Comercial</label
                        >
                      </slot>
                      <el-input type="text" v-model="form.tradename"></el-input>
                    </el-form-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col xs="12" sm="12" md="6">
                    <el-form-item prop="email">
                      <slot name="label">
                        <label class="font-semi-bold m-0">Correo</label>
                      </slot>
                      <el-input type="text" v-model="form.email"></el-input>
                    </el-form-item>
                  </b-col>
                  <b-col xs="12" sm="12" md="6"> </b-col>
                </b-row>
              </div>
            </div>
            <div class="card card-shadow my-3">
              <div class="card-body card-shadow-body">
                <h4 class="h4 font-bold line-bottom mb-4  text-color-primary">
                  Información facturación
                </h4>

                <b-row>
                  <b-col xs="12" sm="12" md="6">
                    <el-form-item prop="document_type">
                      <slot name="label">
                        <label class="font-semi-bold m-0"
                          >Tipo de documento</label
                        >
                      </slot>
                      <el-select
                        v-model="form.document_type"
                        placeholder="Seleccionar"
                        @change="loadDefaultValue()"
                      >
                        <el-option
                          v-for="document in documentTypes"
                          :key="document.id"
                          :label="document.name"
                          :value="document.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </b-col>
                  <b-col xs="12" sm="12" md="6">
                    <el-form-item prop="document">
                      <slot name="label">
                        <label class="font-semi-bold m-0">Documento</label>
                      </slot>
                      <el-input type="text" v-model="form.document"></el-input>
                    </el-form-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col xs="12" sm="12" md="6">
                    <el-form-item prop="name">
                      <slot name="label">
                        <label class="font-semi-bold m-0">Razón Social</label>
                      </slot>
                      <el-input type="text" v-model="form.name"></el-input>
                    </el-form-item>
                  </b-col>
                  <b-col xs="12" sm="12" md="6">
                    <el-form-item prop="address">
                      <slot name="label">
                        <label class="font-semi-bold m-0">Dirección</label>
                      </slot>
                      <el-input type="text" v-model="form.address"></el-input>
                    </el-form-item>
                  </b-col>
                </b-row>
              </div>
            </div>

            <div class="card card-shadow my-3">
              <div class="card-body card-shadow-body">
                <h4 class="h4 font-bold line-bottom mb-4 text-color-primary">
                  Información complementaria
                </h4>
                <b-row>
                  <b-col xs="12" sm="12" md="6">
                    <el-form-item prop="web">
                      <slot name="label">
                        <label class="font-semi-bold m-0">Sitio web</label>
                      </slot>
                      <el-input type="text" v-model="form.web"></el-input>
                    </el-form-item>
                  </b-col>

                  <b-col xs="12" sm="12" md="6">
                    <el-form-item prop="city_id">
                      <slot name="label">
                        <label class="font-semi-bold m-0">Ciudad</label>
                      </slot>
                      <el-select
                        filterable
                        v-model="form.city_id"
                        placeholder="Seleccionar"
                      >
                        <el-option
                          v-for="city in cities"
                          :key="city.id"
                          :label="city.name"
                          :value="city.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col xs="12" sm="12" md="6">
                    <el-form-item prop="phone">
                      <slot name="label">
                        <label class="font-semi-bold m-0">Teléfono</label>
                      </slot>
                      <el-input type="text" v-model="form.phone"></el-input>
                    </el-form-item>
                  </b-col>

                  <b-col xs="12" sm="12" md="6"> </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
          <b-col xs="12" sm="12" md="6">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <h4 class="h4 font-bold text-color-primary">
                Contactos
              </h4>
              <LinkPlusComponent
                @onClick="addContacts"
                title="Agregar contacto"
              />
            </div>
            <div v-if="form.contacts.length >= 1">
              <div
                class="card card-shadow my-3"
                :key="indexContact"
                v-for="(contact, indexContact) in form.contacts"
              >
                <div class="card-body card-shadow-body">
                  <b-row>
                    <b-col sm="12" md="12" lg="6" xl="6">
                      <FileUploadComponent
                        icon="el-icon-user"
                        title="Subir Foto"
                        className="image-profile"
                        :showFileList="false"
                        @fileBase64="
                          base64 => onFileBase64Contact(base64, indexContact)
                        "
                      />
                    </b-col>
                    <b-col sm="12" md="12" lg="6" xl="6">
                      <ButtonDeleteComponent
                        :isShowButton="false"
                        @onConfirmDelete="removeContacts(indexContact, contact)"
                      />
                    </b-col>
                  </b-row>
                  <h4 class="h4 font-bold line-bottom my-4 text-color-primary">
                    Información básica
                  </h4>
                  <b-row>
                    <b-col sm="12" md="6" lg="6" xl="6">
                      <b-row>
                        <b-col sm="12">
                          <el-form-item
                            :prop="'contacts.' + indexContact + '.name'"
                            :rules.sync="
                              getSyncRules && getSyncRules.rules.required()
                            "
                          >
                            <slot name="label">
                              <label class="font-semi-bold m-0"
                                >Nombre de contacto</label
                              >
                            </slot>
                            <el-input
                              type="text"
                              v-model="contact.name"
                            ></el-input>
                          </el-form-item>
                        </b-col>
                        <b-col sm="12">
                          <el-form-item
                            :prop="'contacts.' + indexContact + '.phone'"
                          >
                            <slot name="label">
                              <label class="font-semi-bold m-0">Teléfono</label>
                            </slot>
                            <el-input
                              type="text"
                              v-model="contact.phone"
                            ></el-input>
                          </el-form-item>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col sm="12" md="6" lg="6" xl="6">
                      <b-row>
                        <b-col sm="12">
                          <el-form-item
                            :prop="'contacts.' + indexContact + '.email'"
                            :rules.sync="
                              getSyncRules && getSyncRules.rules.required()
                            "
                          >
                            <slot name="label">
                              <label class="font-semi-bold m-0">Correo</label>
                            </slot>
                            <el-input
                              type="text"
                              v-model="contact.email"
                            ></el-input>
                          </el-form-item>
                        </b-col>
                        <b-col sm="12">
                          <el-form-item
                            :prop="'contacts.' + indexContact + '.position'"
                          >
                            <slot name="label">
                              <label class="font-semi-bold m-0">Cargo</label>
                            </slot>
                            <el-input
                              type="text"
                              v-model="contact.position"
                            ></el-input>
                          </el-form-item>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="card-body card-shadow-body">
                <div class="text-center">
                  <img :src="bgContacts" alt="" />
                  <h4 class="h4 font-bold my-4 text-color-primary">
                    Puedes agregar <span class="el-span">+</span> varios
                    contactos de una empresa.
                  </h4>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </el-form>
    <WrapperBusinessTourComponent />
  </div>
</template>

<script>
import TopBarTitleComponent from "../../components/TopBarTitle";
import FileUploadComponent from "../../components/FileUpload";
import ButtonPrimaryComponent from "../../components/ButtonPrimary";
import LinkPlusComponent from "@/modules/dashboard/components/LinkPlus";
import Validate from "@/assets/validate";
import businessService from "../services/businessService";
import CitiesServices from "@/services/CitiesServices";
import { documentTypes, businessTypes } from "@/services/Utils";
import WrapperBusinessTourComponent from "./WrapperTour";
import ButtonDeleteComponent from "../../components/ButtonDelete";
import { bgContacts } from "@/services/resources";

export default {
  name: "BusinessAddComponent",
  data() {
    const checkDocument = (rule, value, callback) => {
      const { document_type } = this.form;
      switch (document_type) {
        case 1:
          if (value.length !== 13) {
            callback(new Error("Introduzca un RUC valido"));
          }
          break;
        case 2:
          if (value.length !== 10) {
            callback(new Error("Introduzca una cédula valida"));
          }
          break;
        case 4:
          if (value.length !== 13 && value != 9999999999999) {
            callback(
              new Error(
                "El valor para el tipo documento Consumidor Final es 9999999999999"
              )
            );
          }
          break;
        case 5:
          if (value.length !== 9) {
            callback(new Error("Introduzca una identificación valida"));
          }
          break;
        default:
          break;
      }
      callback();
    };
    return {
      bgContacts,
      documentTypes,
      businessTypes,
      cities: [],
      form: {
        name: "",
        tradename: "",
        email: "",
        document_type: "",
        document: "",
        address: "",
        phone: "",
        image: "",
        web: "",
        city_id: "",
        contact_type: "",
        contacts: []
      },
      rules: {
        // name: [Validate.rules.required()],
        tradename: [Validate.rules.required()],
        // address: [Validate.rules.required()],
        email: [Validate.rules.required(), Validate.rules.email()],
        // document_type: [Validate.rules.required("change")],
        document: [
          // Validate.rules.required(),
          { validator: checkDocument, trigger: "blur" }
        ],
        contact_type: [Validate.rules.required("change")]
        // address: [Validate.rules.required()],
        // phone: [Validate.rules.required()],
        // city_id: [Validate.rules.required("change")]
        // tipocontacto_id: [Validate.rules.required()]
      }
    };
  },
  computed: {
    getSyncRules() {
      return Validate;
    }
  },
  methods: {
    loadDefaultValue() {
      this.form.document = this.form.document_type == 4 ? 9999999999999 : "";
    },
    onFileBase64(base64) {
      this.form.image = base64;
    },
    onFileBase64Contact(base64, indexContact) {
      this.form.contacts[indexContact].image = base64;
    },
    onChangeInput(data) {
      this.form = { ...this.form, ...data };
    },
    resetContacts() {
      this.form.contacts = [];
    },
    addContacts() {
      this.form.contacts = [
        ...[
          {
            name: "",
            position: "",
            phone: "",
            email: "",
            image: ""
          }
        ],
        ...this.form.contacts
      ];
    },
    removeContacts(index) {
      this.form.contacts.splice(index, 1);
    },
    handleSaveClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          try {
            let response = await businessService
              .addBusiness(this.form)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });
            const { success, message, client } = response.data;
            if (success) {
              this.$refs.form.resetFields();
              this.resetContacts();
              this.$root.$emit("cleanFileUpload");
              this.onFileBase64("");

              this.$notifications.success({
                message
              });

              this.$emit("onSuccess", { client });
            }
          } catch (error) {
            return false;
          }
        }
      });
    },

    async getCities() {
      await CitiesServices.getCities().then(response => {
        if (response.data.success) this.cities = response.data.cities;
      });
    }
  },
  components: {
    TopBarTitleComponent,
    FileUploadComponent,
    ButtonPrimaryComponent,
    LinkPlusComponent,
    WrapperBusinessTourComponent,
    ButtonDeleteComponent
  },
  props: {
    showBackIcon: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.getCities();
  }
};
</script>

<style lang="scss" scoped>
.border-right {
  border-right: solid 1px #e3e3e3;
}

.el-icon-delete {
  font-size: 1.5rem;
}

.el-span {
  color: #3fdbbf;
}
</style>
