<template>
  <div>
    <template v-if="autocomplete">
      <el-autocomplete
        popper-class="my-autocomplete"
        v-model="productSelect"
        :placeholder="placeholder"
        :fetch-suggestions="querySearch"
        :trigger-on-focus="false"
        @select="onSelectProduct"
        suffix-icon="el-icon-search"
        class="mt-4 mb-2"
      >
        <template slot-scope="{ item }">
          <div class="value">{{ item.name }}</div>
        </template>
      </el-autocomplete>
      <LinkPlusComponent
        v-if="showAdd"
        title="Añadir nuevo producto o servicio"
        @onClick="onAddProducts"
      />

      <el-dialog
        :visible.sync="dialogProductsAdd"
        :fullscreen="true"
        :before-close="handleCloseProductsAdd"
      >
        <ProductAddComponent
          :showBackIcon="false"
          v-if="dialogProductsAdd"
          @onSuccess="onSuccessProductsAdd"
        />
      </el-dialog>
    </template>
    <template v-if="select">
      <el-select
        filterable
        multiple
        v-model="productSelects"
        :placeholder="placeholder"
        @change="onChangeSelect"
      >
        <el-option
          v-for="product in products"
          :key="product.id"
          :label="product.name"
          :value="product.id"
        ></el-option>
      </el-select>
    </template>
  </div>
</template>

<script>
import LinkPlusComponent from "@/modules/dashboard/components/LinkPlus";
import ProductAddComponent from "../catalogue/components/products/Add";
import catalogueService from "../catalogue/services/catalogueService";

export default {
  name: "SelectProductsComponent",
  data: () => ({
    searchProduct: null,
    products: [],
    productSelect: null,
    productSelects: [],
    dialogProductsAdd: false
  }),
  methods: {
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    async querySearch(queryString, cb) {
      if (!this.validateEmissionPoint) {
        this.searchProduct = queryString;
        await this.getProducts();
        cb(this.products);
        return;
      }

      if (this.emisionPointId === null) {
        this.$notifications.warning({
          message: "Seleccione un punto de emisión antes de buscar productos"
        });
        this.productSelect = null;
      } else {
        this.searchProduct = queryString;
        await this.getProducts();
        cb(this.products);
      }
    },
    onSelectProduct(product) {
      if (this.validateStock && product.is_stock_counter) {
        const found = product.stocks.find(item => {
          return item.establishment_emision_points.some(emisionPoint => {
            return emisionPoint.id === this.emisionPointId;
          });
        });

        if (found && found.quantity <= 0) {
          this.$notifications.warning({
            message: "Este producto no cuenta con stock disponible"
          });
        } else {
          product.stock = 1;
          this.$emit("onSelectProduct", { product });
        }
      } else {
        product.stock = 1;
        this.$emit("onSelectProduct", { product });
      }
    },
    onAddProducts() {
      this.dialogProductsAdd = true;
    },
    onChangeSelect() {
      this.$emit("onSelectProduct", { products: this.productSelects });
    },
    onSuccessProductsAdd({ product }) {
      this.products = [product, ...this.products];
    },
    handleCloseProductsAdd() {
      this.dialogProductsAdd = false;
    },
    async getProducts() {
      await catalogueService
        .getProducts({
          params: {
            search: this.searchProduct,
            limit: 100
          }
        })
        .then(response => {
          if (response.data.products) this.products = response.data.products;
        });
    }
  },
  components: {
    LinkPlusComponent,
    ProductAddComponent
  },
  props: {
    placeholder: {
      default: "Buscar un producto o servicio por código o nombre"
    },
    autocomplete: {
      default: false,
      type: Boolean
    },
    select: {
      default: false,
      type: Boolean
    },
    showAdd: {
      default: true,
      type: Boolean
    },
    emisionPointId: {
      default: null,
      type: Number
    },
    validateEmissionPoint: {
      type: Boolean,
      default: false
    },
    validateStock: {
      type: Boolean,
      default: false
    }
  },
  updated() {
    this.getProducts();
  }
};
</script>

<style lang="sass" scoped>
.el-autocomplete
  width: 100% !important;
.my-autocomplete
  li
    line-height: normal;
    padding: 7px;
    .value
      text-overflow: ellipsis;
      overflow: hidden;
    .link
      font-size: 12px;
      color: #b4b4b4;
</style>
