<template>
  <el-popover placement="bottom" width="250" v-model="visible">
    <p>En caso si desea sincronizar debera activar el estado del producto y guardar el registro, ¿Seguro en querer eliminar?</p>
    <div style="text-align: right; margin: 0">
      <button class="btn md__btn-link" @click="visible = false">
        Cancelar
      </button>
      <button
        class="btn md__btn-link text-color-secondary"
        @click="handleOnConfirm"
      >
        Confirmar
      </button>
    </div>
    <button
      type="button"
      class="btn md__btn-third-secondary cursor-point"
      slot="reference"
      v-if="isShowButton"
    >
      Eliminar
    </button>
    <button
      type="button"
      class="btn md__btn-link bgt p-0 m-0 float-right cursor-point"
      slot="reference"
      v-else
    >
      <img :src="icoTrash" alt="ico trash" />
    </button>
  </el-popover>
</template>

<script>
import { icoTrash } from "@/services/resources";

export default {
  name: "ButtonDeleteComponent",
  data() {
    return {
      icoTrash,
      visible: false
    };
  },
  methods: {
    handleOnConfirm() {
      this.$emit("onConfirmDelete");
      this.visible = false;
    }
  },
  props: {
    isShowButton: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style></style>
