import { render, staticRenderFns } from "./SelectProducts.vue?vue&type=template&id=24a19ebf&scoped=true"
import script from "./SelectProducts.vue?vue&type=script&lang=js"
export * from "./SelectProducts.vue?vue&type=script&lang=js"
import style0 from "./SelectProducts.vue?vue&type=style&index=0&id=24a19ebf&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24a19ebf",
  null
  
)

export default component.exports