<template>
  <div>
    <template>
      <slot name="label">
        <div class="d-flex justify-content-between align-items-center">
          <label class="font-semi-bold m-0 d-block">{{ title }}</label>
          <LinkPlusComponent
            onlyIcon
            @onClick="onAddBusiness"
            v-if="isAddBusiness"
          />
        </div>
      </slot>
      <el-autocomplete
        v-model="userBusinessClientName"
        :placeholder="placeholder"
        :fetch-suggestions="querySearch"
        @select="onSelect"
        suffix-icon="el-icon-search"
      >
        <template slot-scope="{ item }">
          <div class="value">{{ item.name }}</div>
        </template>
      </el-autocomplete>
      <el-dialog
        :visible.sync="dialogBusinessAdd"
        :fullscreen="true"
        :before-close="handleCloseBusinessAdd"
      >
        <BusinessAddComponent
          :showBackIcon="false"
          v-if="dialogBusinessAdd"
          @onSuccess="onSuccessBusinessAdd"
        />
      </el-dialog>
    </template>
  </div>
</template>

<script>
import LinkPlusComponent from "@/modules/dashboard/components/LinkPlus";
import businessService from "../business/services/businessService";
import BusinessAddComponent from "../business/components/Add";

export default {
  name: "SelectBusinessV2Component",
  data: () => ({
    searchBusiness: null,
    clients: [],
    contacts: [],
    dialogProductsAdd: false,
    userBusinessClientId: null,
    userBusinessClientName: null,
    dialogBusinessAdd: false,
    selectEdit: false
  }),
  methods: {
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    async querySearch(queryString, cb) {
      if (this.setUserBusinessClientId != null && this.selectEdit) {
        this.selectEdit = false;
        this.userBusinessClientName = null;
        this.userBusinessClientId = null;
      }
      this.searchBusiness = queryString;
      await this.getBusiness();
      cb(this.clients);
    },
    onAddBusiness() {
      this.dialogBusinessAdd = true;
      this.userBusinessClientId = null;
      this.userBusinessClientName = null;
    },
    onSelect(item) {
      if (item) {
        this.userBusinessClientName = item.name;
        this.clients.map(client => {
          if (client.id === item.id) {
            this.contacts = client.contacts;
          }
        });

        const dataClient = this.clients.find(client => client.id === item.id);
        this.$emit("onChangeSelect", {
          client: dataClient,
          contacts: this.contacts
        });
      }
    },
    onSuccessBusinessAdd({ client }) {
      this.clients = [client, ...this.clients];
    },
    handleCloseBusinessAdd() {
      this.dialogBusinessAdd = false;
    },
    async getBusiness() {
      this.loading = true;
      await businessService
        .getBusiness({
          params: {
            name: this.searchBusiness,
            limit: 100
          }
        })
        .then(response => {
          if (response.data.clients) {
            this.clients = response.data.clients;

            if (this.setUserBusinessClientId != null && this.selectEdit) {
              const data = response.data.clients.find(
                f => f.id === this.setUserBusinessClientId
              );
              this.userBusinessClientName = data?.name;
            }

            if (this.onlyProviders) {
              this.clients = response.data.clients.filter(client => {
                return client.contact_type == 2;
              });
            }
          }
        })
        .finally(() => (this.loading = false));
    }
  },
  components: {
    LinkPlusComponent,
    BusinessAddComponent
  },
  props: {
    title: {
      default: "Empresa"
    },
    placeholder: {
      default: "Buscar empresa"
    },
    autocomplete: {
      default: false,
      type: Boolean
    },
    select: {
      default: false,
      type: Boolean
    },
    isAddBusiness: {
      default: true,
      type: Boolean
    },
    setUserBusinessClientId: {
      default: null
    }
  },
  mounted() {
    if (this.setUserBusinessClientId != null) {
      this.selectEdit = true;
      this.getBusiness();
      this.userBusinessClientId = this.setUserBusinessClientId;
    }
  }
};
</script>

<style lang="sass" scoped>
.el-autocomplete
  width: 100% !important;
.my-autocomplete
  li
    line-height: normal;
    padding: 7px;
    .value
      text-overflow: ellipsis;
      overflow: hidden;
    .link
      font-size: 12px;
      color: #b4b4b4;
</style>
