<template>
  <el-input
    v-model.number="discount"
    @blur="onChange"
    type="text"
    placeholder=""
    class="number-descount input-switch-discount"
  >
    <template slot="append">
      <el-radio-group size="small" @change="onChange" v-model="type">
        <el-radio-button label="percentage">%</el-radio-button>
        <!-- <el-radio-button label="amount" disabled>$</el-radio-button> -->
      </el-radio-group>
    </template>
  </el-input>
</template>

<script>
export default {
  name: "SwitchDiscountComponent",
  data() {
    return {
      discount: this.setDiscount,
      type: this.setDiscountType
    };
  },
  watch: {
    setDiscount(v) {
      this.discount = v;
    },
    setDiscountType(v) {
      this.type = v;
    },
    discount(v) {
      this.discount = Number.isNaN(Number.parseFloat(v)) ? 0 : parseFloat(v);
    }
  },
  methods: {
    onChange() {
      this.$emit("onChange", {
        discount: this.discount,
        type: this.type
      });
    }
  },
  props: {
    setDiscount: {
      default: 0
    },
    setDiscountType: {
      default: "percentage"
    }
  },
  mounted() {
    this.onChange();
  }
};
</script>

<style lang="scss" scoped></style>
