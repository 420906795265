<template>
  <span>${{ onParseFloatFormat(formatAmount) }}</span>
</template>

<script>
import { parseFloatFormat } from "@/assets/utils/format";
import abbreviate from "number-abbreviate";

export default {
  name: "FormatAmount",
  data() {
    return {
      formatAmount: this.amount
    };
  },
  watch: {
    amount(v) {
      this.formatAmount = v;
    }
  },
  methods: {
    onParseFloatFormat(value) {
      return Number.isNaN(value)
        ? parseFloatFormat(0)
        : this.abbreviate
        ? abbreviate(parseFloatFormat(value), 1)
        : parseFloatFormat(value);
    }
  },
  props: {
    amount: {
      default: 0
    },
    abbreviate: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped></style>
