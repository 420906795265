import Api from "@/config/Api";

export default {
  addBusiness(data) {
    return Api().post("/clients", data);
  },

  getBusinessId(businessId) {
    return Api().get(`/clients/${businessId}`);
  },

  getBusiness(options = {}) {
    return Api().get("/clients", options);
  },

  updateBusinessId(business) {
    return Api().put(`/clients/${business.id}`, business);
  },

  deleteBusinessId(businessId) {
    return Api().delete(`/clients/${businessId}`);
  },

  getContacts(options = {}) {
    return Api().get("/clients/contacts", options);
  },

  updateContactId(contact) {
    return Api().put(`/clients/contacts/${contact.id}`, contact);
  },

  addContact(data) {
    return Api().post("/clients/contacts", data);
  },

  deleteContactId(contactId) {
    return Api().delete(`/clients/contacts/${contactId}`);
  }
};
