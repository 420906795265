export const limitCredits = [
  {
    value: "p-15",
    label: "15 días"
  },
  {
    value: "p-30",
    label: "30 días"
  },
  {
    value: "p-60",
    label: "60 días"
  },
  {
    value: "p-90",
    label: "90 días"
  },
  {
    value: "others",
    label: "Otro"
  }
];
